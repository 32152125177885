//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable import/no-unresolved */
import FeatureCard from '@/components/UI/FeatureCard';
import statsMixin from '@/mixins/statsMixin';
import SubmitButton from '@/components/UI/Button/SubmitButton';
import UICounterSheetBased from '@/components/UI/UICounterSheetBased';

export default {
  mixins: [statsMixin],
  components: {
    FeatureCard,
    SubmitButton,
    UICounterSheetBased,
  },
  data: () => ({
    cardFeatures: [
      {
        title: 'index.cards[0].title',
        description: 'index.cards[0].description',
        path: '/apps/expression-base',
        imgPath: 'img/faster.png',
        class: 'right',
        color: 'customOne lighten-5',
        isReversed: false,
      },
      {
        title: 'index.cards[1].title',
        description: 'index.cards[1].description',
        path: '/apps/expression-base?go=01',
        imgPath: 'img/publics.png',
        class: 'left',
        color: 'red lighten-5',
        isReversed: true,
      },
      {
        title: 'index.cards[2].title',
        description: 'index.cards[2].description',
        path: '/apps/expression-base?go=07',
        imgPath: 'img/massive.png',
        class: 'right',
        color: '#fff6db',
        isReversed: false,
      },
      {
        title: 'index.cards[3].title',
        description: 'index.cards[3].description',
        path: '/apps/expression-base?go=06',
        imgPath: 'img/controls.png',
        class: 'left',
        color: 'primary lighten-5',
        isReversed: true,
      },
      {
        title: 'index.cards[4].title',
        description: 'index.cards[4].description',
        path: '/apps/expression-base?go=00',
        imgPath: 'img/acess.png',
        class: 'right',
        color: '#fff6db',
        isReversed: false,
      },
      {
        title: 'index.cards[5].title',
        description: 'index.cards[5].description',
        path: '/apps/expression-base?go=09',
        imgPath: 'img/master.png',
        class: 'left',
        color: 'red lighten-5',
        isReversed: true,
      },
    ],
  }),
  mounted() {
    this.$vuetify.rtl =
      this.$i18n.locale === 'ar'
        ? (this.$vuetify.rtl = true)
        : (this.$vuetify.rtl = false);
  },
  methods: {
    toBlogPost(id) {
      this.$router.push(this.localePath(`/blog/posts/${id}`));
    },
    startAccountCreation() {
      this.$fn.redirect(this, 'expressionBasePricing');
    },
    moveToLearnMore() {
      this.$fn.redirect(this, 'expressionBase');
    },
  },
};
