var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"hero-4-bg background",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"6","xs":"12"}},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"display-2"},[_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t('index.title1'))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('index.title2')))])]),_vm._v(" "),_c('p',{staticClass:"ma-2"},[_vm._v("\n          "+_vm._s(_vm.$t('index.subtitle1'))+"\n          "),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t('index.subtitle2'))+" ")]),_vm._v(_vm._s(_vm.$t('index.subtitle3'))+"\n        ")]),_vm._v(" "),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"6","lg":"4"}},[_c('SubmitButton',{attrs:{"options":{
                color: 'customOne white--text',
                isDark: true,
                isXLarge: true,
                icon: 'mdi-download',
              },"label":"buttons.download_now"},on:{"formSubmit":_vm.startAccountCreation}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"10","md":"6","lg":"4"}},[_c('SubmitButton',{attrs:{"options":{
                color: 'customOne',
                class: 'customOne--text',
                isDark: true,
                isXLarge: true,
                outlined: true,
                icon: 'mdi-information',
              },"label":"buttons.learn_more"},on:{"formSubmit":_vm.moveToLearnMore}})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('v-img',{attrs:{"max-width":"500px","src":"img/cover.png"}})],1)],1)],1)])],1),_vm._v(" "),_vm._l((_vm.cardFeatures),function(f,i){return _c('v-row',{key:i + 'S',attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10","lg":"8"}},[_c('FeatureCard',{class:f.class,attrs:{"title":_vm.$t(f.title),"description":_vm.$t(f.description),"path":f.path,"imgPath":f.imgPath,"color":f.color,"isReversed":f.isReversed}})],1)],1)}),_vm._v(" "),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"8"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('UICounterSheetBased',{attrs:{"options":{
              counter: _vm.nbUsersExpressions,
              icon: 'mdi-code-tags',
              color: 'customOne',
              divClass: 'circle-two',
              label: 'index.stats[0].title',
            }}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('UICounterSheetBased',{attrs:{"options":{
              counter: _vm.nbPublicExpressions,
              icon: 'mdi-code-json',
              color: 'warning',
              divClass: 'circle-three',
              label: 'index.stats[1].title',
            }}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('UICounterSheetBased',{attrs:{"options":{
              counter: _vm.nbInstalls,
              icon: 'mdi-account-group-outline',
              label: 'index.stats[2].title',
              divClass: 'circle',
            }}})],1)],1)],1)],1),_vm._v(" "),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-center"},[_vm._v("\n            "+_vm._s(_vm.$t('index.callout'))+"\n          ")])])],1),_vm._v(" "),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","sm":"4","md":"3"}},[_c('SubmitButton',{attrs:{"options":{
              color: 'customOne white--text',
              isDark: true,
              isXLarge: true,
              icon: 'mdi-download',
            },"label":"buttons.download_now"},on:{"formSubmit":_vm.startAccountCreation}})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }