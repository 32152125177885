//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    options: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultOptions: {
        color: 'red',
        counter: null,
        icon: null,
        label: null,
        divClass: null,
      },
    };
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
  },
};
