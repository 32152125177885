//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // name: 'SubmitButton',
  props: {
    label: {
      required: true,
      type: String,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    submit() {
      this.$emit('formSubmit');
    },
  },
  data() {
    return {
      defaultOptions: {
        block: true,
        depressed: true,
        color: 'primary',
        class: 'mt-0',
        loader: false,
        disabled: false,
        isDark: false,
        isLarge: false,
        isXLarge: false,
        isRounded: false,
        isJustify: null,
        outlined: null,
        justify: 'center',
        spanClass: null,
        href: null,
        icon: null,
      },
    };
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
  },
};
