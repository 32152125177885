/* eslint-disable func-names */
/* eslint-disable prefer-destructuring */
export default {
  computed: {
    forEachBreakpoint() {
      return function (values) {
        let breakpoint;
        const [xs, sm, md, lg, xl] = values;
        /* eslint-disable prefer-destructuring */
        // eslint-disable-next-line default-case
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            breakpoint = xs;
            break;
          case 'sm':
            breakpoint = sm;
            break;
          case 'md':
            breakpoint = md;
            break;
          case 'lg':
            breakpoint = lg;
            break;
          case 'xl':
            breakpoint = xl;
            break;
        }
        return breakpoint;
      };
    },
  },
};
