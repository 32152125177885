//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line import/no-unresolved
import uiMixin from '@/mixins/uiMixin';

export default {
  mixins: [uiMixin],
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    path: {
      required: true,
      type: String,
    },
    imgPath: {
      required: true,
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    isReversed: {
      type: Boolean,
    },
  },
};
