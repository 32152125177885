/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapState } from 'vuex';

export default {
  computed: {
    // TODO: load ratings and average from store
    // TODO: load version number from store
    ...mapState({
      posts: (state) => state.posts.list,
      nbInstalls: (state) => state.posts.userCount,
      nbPublicExpressions: (state) => state.posts.publicExpressionCount,
      nbUsersExpressions: (state) => state.posts.userExpressionCount,
    }),
  },
};
